<template>
  <div class="container-fluid">
    <navbar id="navbar"></navbar>
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <router-view />
        </div>
      </div>
    </div>
    <pie></pie>
  </div>
</template>

<script>
import navbar from './components/Navbar.vue'
import pie from './components/pie.vue'

export default {
  name: 'App',
  components: {
    navbar,
    pie,
  }
}
</script>

<style scoped>

</style>
