<template>
  <div>
    <div id="carousel-fade">
      <b-carousel
        id="carousel-1"
        :interval="2000"
        img-width="3280"
        img-height="798"
        fade no-hover-pause
      >
        <b-carousel-slide>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <h5 class="text-dark">Con</h5>
                <h1 class="text-uppercase text-dark">Planeación</h1>
                <div class="h2 w-75 float-right mb-0 text-dark">
                  <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
                </div>
              </div>
            </div>
          </div>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="3280"
              height="798"
              src="../assets/slideshow/gc2.png"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <h5 class="text-dark">Desarrollamos</h5>
          <h1 class="text-uppercase text-dark">Estrategias</h1>
          <div class="h2 w-75 float-right mb-0 text-dark">
            <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
          </div>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="3280"
              height="798"
              src="../assets/slideshow/gc4.png"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <h5 class="text-dark">Ofrecemos</h5>
          <h1 class="text-uppercase text-dark">Resultados</h1>
          <div class="h2 w-75 float-right mb-0 text-dark">
            <b-icon icon="arrow-right" aria-hidden="true"></b-icon>
          </div>
          <template #img>
            <img
              class="d-block img-fluid w-100"
              width="3280"
              height="798"
              src="../assets/slideshow/gc5.png"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>

    <section id="about_us">
      <b-row class="text-justify justify-content-center mx-auto d-lg-none">
        <b-col md="12" sm="12" class="bg-yellow py-5">
          <h1 class="text-center">NOSOTROS</h1>
          <p class="w-100">
            <strong
              >Somos una compañía joven de tecnología multidisciplinar
              especializada en desarrollo de contenidos digitales,</strong
            >
            brindamos asesoría desde la fase inicial de la construcción de una
            marca hasta la implementación de la estrategia de comunicación y
            promoción en todas las áreas así como servicios de IT avanzados.
          </p>
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<script>
export default {
  name: "home",
  title: "G y C Marketing | Inicio",
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {},
};
</script>

<style scoped>
h1 {
  font-size: 150px;
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  body {
    min-width: 100%;
  }

  h1 {
    font-size: 35px;
    font-family: fantasy;
  }

  #about_us {
    min-height: 200px;
  }

  img {
    min-height: 400px;
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
  body {
    min-width: 100%;
  }

  h1 {
    font-size: 35px;
    font-family: fantasy;
  }
}

@media screen and (max-width: 620px) {
  body {
    min-width: 100%;
  }

  h1 {
    font-size: 35px;
    font-family: fantasy;
  }

  #about_us {
    min-height: 200px;
  }

  img {
    min-height: 400px;
  }

  p {
    font-size: 13px;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 150px;
    font-family: fantasy;
    text-align: center;
  }
  body {
    min-width: 100%;
  }

  img {
    min-height: 300px;
  }

  p {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 70px;
    font-family: fantasy;
  }

  body {
    min-width: 100%;
  }

  img {
    min-height: 300px;
  }

  p {
    font-size: 18px;
  }

  #about_us {
    min-height: 400px;
  }
}

@media (max-width: 280px) {
  h1 {
    font-size: 40px;
    font-family: fantasy;
  }
  body {
    min-width: 100%;
  }

  img {
    min-height: 300px;
  }

  p {
    font-size: 13px;
  }
}

@media (max-width: 500px) {
  h1 {
    font-size: 40px;
    font-family: fantasy;
  }
  body {
    min-width: 100%;
  }

  img {
    min-height: 400px;
  }

  p {
    font-size: 13px;
  }

   #about_us {
    min-height: 400px;
  }
}

</style>
